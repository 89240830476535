.App {
  text-align: center;
}

.navbarStyle {
  padding: .5rem 1rem !important
}

.navbar-logo{
  height: auto;
  width: auto;
  max-width: 100px;
  max-height: 100px;
}

form {
  display: flex;
  flex-direction: column;
  width: 30rem;
  margin-left: auto;
  margin-right: auto;
  gap: 1rem;
}

form span {
  display: flex;
  font-weight: bold;
  text-transform: uppercase;
}

form select {
  margin-bottom: 0.7rem;
}

.username {
  font-weight: 100;
  margin-right: 0.5rem;
  color: beige;
}